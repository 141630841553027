import React from 'react'

function DanceStudioIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        d="M20.62 13.482l5.773 6.192 3.868 2.4 3.43-.972-.686-2.7c-.223-.88.258-1.788 1.077-2.029.818-.24 1.663.278 1.886 1.158l.959 3.772c.285 1.12-.288 2.274-1.29 2.656l-.163.054-5.281 1.497-2.205 4.106 2.991 1.857c.634.394 1.024 1.12 1.024 1.907v6.969c0 .912-.688 1.651-1.536 1.651-.848 0-1.535-.74-1.536-1.651v-6.333l-1.863-1.157.221.524a2.353 2.353 0 010 1.82l-2.46 5.828c-.35.83-1.261 1.198-2.033.82-.727-.354-1.073-1.242-.819-2.037l.055-.148 2.268-5.374-1.924-4.557a2.306 2.306 0 01-.47-1.296 1.763 1.763 0 01.13-.894c.039-.108.086-.215.142-.319l2.524-4.701-6.25-6.704a1.74 1.74 0 01-.004-2.336 1.461 1.461 0 012.172-.003z"
      ></path>
      <path
        fill="#fff"
        d="M32.182 16.636c0 1.456-1.16 2.637-2.591 2.637-1.431 0-2.591-1.18-2.591-2.637C27 15.18 28.16 14 29.59 14c1.432 0 2.592 1.18 2.592 2.636z"
      ></path>
    </svg>
  )
}

export default DanceStudioIcon
