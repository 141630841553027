import React from 'react'
import PropTypes from 'prop-types'
import SpaceTypesCard from './space-types-card'
import classnames from 'classnames'

const propTypes = {
  spaceTypes: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
}

const defaultProps = {
  className: '',
}

function SpaceTypesContent({ spaceTypes, className }) {
  return (
    <div
      className={classnames(
        'tab-content',
        'colored-block',
        'colorless-block',
        className
      )}
    >
      {spaceTypes.map((spaceType) => (
        <SpaceTypesCard
          key={spaceType.id}
          name={spaceType.name}
          displayName={spaceType.displayName}
        />
      ))}
    </div>
  )
}

SpaceTypesContent.propTypes = propTypes
SpaceTypesContent.defaultProps = defaultProps

export default SpaceTypesContent
