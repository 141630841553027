import React from 'react'

function GreenRoomIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        d="M43.38 16.787a.637.637 0 00.62-.64.637.637 0 00-.62-.643h-1.33v-2.862a.653.653 0 00-.185-.455.629.629 0 00-.895 0 .653.653 0 00-.185.455v2.862h-2.433v-.705a.9.9 0 00-.89-.904H18.452a.882.882 0 00-.63.265.908.908 0 00-.26.639v.705h-2.345v-2.861a.653.653 0 00-.185-.456.629.629 0 00-.895 0 .653.653 0 00-.185.456v2.86h-1.33a.638.638 0 00-.623.643c0 .351.278.636.624.641h1.33v18.254h-.773a.634.634 0 00-.633.643.634.634 0 00.633.64h2.807a.637.637 0 00.622-.64.638.638 0 00-.622-.643h-.77V16.787h2.345v17.684a3.74 3.74 0 01-.627 2.084L13.29 39.59a.92.92 0 00-.044.93.893.893 0 00.783.48h27.855a.893.893 0 00.784-.48.92.92 0 00-.044-.93l-3.653-3.035a3.808 3.808 0 01-.62-2.084V16.787h2.434v18.254h-.772.002a.637.637 0 00-.63.643c0 .352.281.639.63.64h2.807a.627.627 0 00.447-.187.647.647 0 00.185-.453c-.037-.798-.865-.628-1.404-.641V16.787h1.33zm-7.253 1.057c0 .354-.282.641-.631.641H20.413a.635.635 0 01-.63-.64c0-.353.281-.64.63-.641h15.083a.634.634 0 01.631.64zM20.306 35.041H35.61c.344.007.62.292.62.641 0 .351-.276.636-.62.642H20.306a.639.639 0 01-.624-.642c0-.35.278-.634.624-.64z"
      ></path>
    </svg>
  )
}

export default GreenRoomIcon
