import React from 'react'

function SteamLabIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <mask id="path-2-inside-1_871_13" fill="#fff">
        <rect width="17" height="5" x="19" y="14" rx="2"></rect>
      </mask>
      <rect
        width="17"
        height="5"
        x="19"
        y="14"
        fill="#fff"
        stroke="#fff"
        strokeWidth="5"
        mask="url(#path-2-inside-1_871_13)"
        rx="2"
      ></rect>
      <mask id="path-3-inside-2_871_13" fill="#fff">
        <rect
          width="10.182"
          height="10.182"
          x="32.364"
          y="17.545"
          rx="2"
          transform="rotate(90 32.364 17.545)"
        ></rect>
      </mask>
      <rect
        width="10.182"
        height="10.182"
        x="32.364"
        y="17.545"
        fill="#fff"
        stroke="#fff"
        strokeWidth="6"
        mask="url(#path-3-inside-2_871_13)"
        rx="2"
        transform="rotate(90 32.364 17.545)"
      ></rect>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="3"
        d="M23.283 26.318h8.016l5.081 14.517a.5.5 0 01-.472.665H18.797a.5.5 0 01-.474-.662l4.96-14.52z"
      ></path>
      <path
        fill="#003E7F"
        d="M30.08 29l3.444 9.664A1 1 0 0132.582 40H21.407a1 1 0 01-.945-1.328L23.825 29h6.255z"
      ></path>
    </svg>
  )
}

export default SteamLabIcon
