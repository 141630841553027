import React from 'react'

function SteamLabAndMakerSpaceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <mask id="path-2-inside-1_616_674" fill="#fff">
        <rect width="17.273" height="6.364" x="18.727" y="13" rx="2"></rect>
      </mask>
      <rect
        width="17.273"
        height="6.364"
        x="18.727"
        y="13"
        fill="#fff"
        stroke="#fff"
        strokeWidth="6"
        mask="url(#path-2-inside-1_616_674)"
        rx="2"
      ></rect>
      <mask id="path-3-inside-2_616_674" fill="#fff">
        <rect
          width="10.182"
          height="10.182"
          x="32.364"
          y="17.546"
          rx="2"
          transform="rotate(90 32.364 17.546)"
        ></rect>
      </mask>
      <rect
        width="10.182"
        height="10.182"
        x="32.364"
        y="17.546"
        fill="#fff"
        stroke="#fff"
        strokeWidth="6"
        mask="url(#path-3-inside-2_616_674)"
        rx="2"
        transform="rotate(90 32.364 17.546)"
      ></rect>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="3"
        d="M23.283 26.318h8.016l5.081 14.517a.5.5 0 01-.472.665H18.797a.5.5 0 01-.474-.662l4.96-14.52z"
      ></path>
      <path
        fill="#003E7F"
        d="M29.64 29l2.917 8.681A1 1 0 0131.609 39H22.38a1 1 0 01-.95-1.312L24.28 29h5.361z"
      ></path>
    </svg>
  )
}

export default SteamLabAndMakerSpaceIcon
