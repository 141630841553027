import React from 'react'

function AuditoriumOrStageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        d="M43 43H13v-1.25a2.5 2.5 0 015 0 2.5 2.5 0 115 0 2.5 2.5 0 015 0 2.5 2.5 0 015 0 2.5 2.5 0 015 0 2.5 2.5 0 115 0V43zM43 37.375H13v-1.25a2.5 2.5 0 015 0 2.5 2.5 0 115 0 2.5 2.5 0 015 0 2.5 2.5 0 015 0 2.5 2.5 0 015 0 2.5 2.5 0 115 0v1.25zM43 30.75V12H13v18.75c5.523 0 10-4.477 10-10v-2.148c1.571.598 3.286.898 5.001.898 1.714 0 3.43-.3 4.999-.898v2.148c0 5.523 4.477 10 10 10z"
      ></path>
    </svg>
  )
}

export default AuditoriumOrStageIcon
