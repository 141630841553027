import React from 'react'

function MarchingBandPracticeSpaceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        d="M27.5 26c-1.416 0-13.5.063-13.5 2.832v12.336c0 2.077 6.986 2.643 10.951 2.77h.095C26.273 44 27.122 44 27.5 44c.283 0 1.227 0 2.454-.063h.095C34.014 43.811 41 43.245 41 41.167V28.833C41 26.062 28.916 26 27.5 26zm-8.59 15.86c-1.417-.252-2.361-.503-2.55-.755v-10.51c.755.188 1.605.377 2.55.566v10.7zm4.908.504c-.944-.063-1.794-.126-2.549-.19V31.414c.85.063 1.794.125 2.55.188v10.763zm5.004.063h-2.644V31.664h2.644v10.763zM27.5 30.09c-6.23 0-10.196-.755-11.046-1.259.85-.503 4.815-1.259 11.046-1.259 6.23 0 10.196.756 11.045 1.26-.85.503-4.814 1.258-11.045 1.258zm6.23 12.084c-.755.063-1.604.126-2.548.189V31.539l2.549-.19v10.826zm4.91-1.07c-.283.189-1.133.503-2.55.755v-10.7a13.865 13.865 0 002.55-.566v10.51zM37.721 9.277a.944.944 0 00-1.336 0l-7.724 7.765c-.372 0-.817.222-1.114.518-.223.222-.446.517-.52.887-.075.444 0 .887.297 1.183.223.222.52.37.891.37.446 0 .891-.222 1.188-.518.298-.295.52-.74.52-1.109l7.799-7.765a.935.935 0 000-1.33zM24.578 22.479c.37.372.815.521 1.186.521.37 0 .667-.149.89-.372.518-.521.444-1.415-.15-2.085-.296-.298-.74-.521-1.11-.521l-7.782-7.743a.936.936 0 00-1.334 0 .947.947 0 000 1.34l7.781 7.817c0 .298.148.745.52 1.043z"
      ></path>
    </svg>
  )
}

export default MarchingBandPracticeSpaceIcon
