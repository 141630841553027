import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import * as icons from './basic'
import { get } from 'lodash'

const propTypes = {
  name: PropTypes.string,
}

function SpaceTypeIcon({ name }) {
  const Icon = get(icons, `${upperCamelCase(name)}Icon`)
  return <Icon />
}

// Transforms string to UpperCamelCase (e.g., visual_arts -> VisualArts)
function upperCamelCase(str = '') {
  return str
    .trim()
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join('')
}

SpaceTypeIcon.propTypes = propTypes

export default pure(SpaceTypeIcon)
