import React from 'react'
import PropTypes from 'prop-types'
import {
  fieldPropTypesWithValue,
  omitLabelProps,
  LabeledField,
} from 'lp-components'
import ReactQuill, { Quill } from 'react-quill'

const propTypes = {
  ...fieldPropTypesWithValue(PropTypes.string),
}

const defaultProps = {}

// Custom handlers for Undo/Redo functionality
function undoChange() {
  this.quill.history.undo()
}

function redoChange() {
  this.quill.history.redo()
}

// Create custom icons
const icons = Quill.import('ui/icons')
icons['undo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`
icons['redo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`

function RichTextInput(props) {
  const {
    input: { value, onChange, name },
    meta, // eslint-disable-line no-unused-vars
    className, // eslint-disable-line no-unused-vars
    ...rest
  } = omitLabelProps(props)

  return (
    <LabeledField {...props}>
      <ReactQuill
        theme="snow"
        modules={{
          toolbar: {
            container: [
              ['bold', 'italic', 'strike'],
              ['link'],
              [{ header: [1, false] }],
              [{ list: 'bullet' }, { list: 'ordered' }],
              [{ indent: '-1' }, { indent: '+1' }],
              ['undo', 'redo'],
            ],
            handlers: {
              undo: undoChange,
              redo: redoChange,
            },
          },
          history: {
            delay: 1000,
            maxStack: 100,
            userOnly: true,
          },
        }}
        formats={[
          'bold',
          'italic',
          'strike',
          'link',
          'header',
          'list',
          'bullet',
          'indent',
        ]}
        {...{
          id: name,
          name,
          onChange,
          value,
          ...rest,
        }}
      />
    </LabeledField>
  )
}

RichTextInput.propTypes = propTypes
RichTextInput.defaultProps = defaultProps

export default RichTextInput
