import React from 'react'

function VisualArtsClassroomIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        d="M23.209 17.435v1.614a.356.356 0 01-.353.359h-6.661a.342.342 0 01-.25-.104.357.357 0 01-.103-.255v-1.614a1.26 1.26 0 01.37-.889c.235-.232.553-.36.882-.354h4.892c.674.003 1.22.557 1.223 1.243zM36.3 22.556l.127-3.148h-5.174l.127 3.148h4.92zm-5.617-3.865a6.652 6.652 0 01-1.947-4.55c.033-2.456 1.707-4.835 4.987-7.08h-.002a.349.349 0 01.384-.006.362.362 0 01.163.355c0 .158-.471 3.876 3.062 4.93.762.155 1.378.721 1.604 1.477.395 1.534-1.092 3.684-2.06 4.874h-6.19zm1.412-2.308c.488.464 1.14.71 1.81.68h.366a.364.364 0 00.321-.393.364.364 0 00-.386-.324 1.958 1.958 0 01-1.623-.479 2.917 2.917 0 01-.658-1.987.356.356 0 00-.335-.372.337.337 0 00-.256.086.347.347 0 00-.114.247c-.015.072-.085 1.605.87 2.562l.005-.02zm-.706 6.89l.81 18.851-.002-.04c0 .915.732 1.7 1.633 1.7.902 0 1.632-.744 1.632-1.66l.813-18.851H31.39zm-6.37 17.38l-.037-.593H14.05l-.042.593c-.056.81.22 1.607.766 2.202.545.593 1.309.93 2.11.928h5.269-.002c.8 0 1.563-.336 2.106-.93.545-.594.822-1.39.764-2.2zM23.843 24.56v-.072a2.471 2.471 0 00-.578-1.433l-.776-.918h-5.95l-.771.923v-.002c-.337.405-.54.907-.579 1.433v.072l8.654-.003zm-2.097-3.317v-1.118h-4.46v1.118l-.146.172h4.752l-.146-.172zm3.184 18.12l-1.035-14.069h-8.759l-1.039 14.07H24.93z"
      ></path>
    </svg>
  )
}

export default VisualArtsClassroomIcon
