import React from 'react'

function NonArtsSpaceUsedForArtsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        stroke="#fff"
        strokeWidth="5"
        d="M13.5 20.019a2.5 2.5 0 011.336-2.213l9-4.737c1.664-.876 3.664.331 3.664 2.213v10.7a2.5 2.5 0 01-1.336 2.212l-9 4.737c-1.664.876-3.664-.331-3.664-2.213v-10.7zM41.5 20.019a2.5 2.5 0 00-1.336-2.213l-9-4.737c-1.664-.876-3.664.331-3.664 2.213v10.7a2.5 2.5 0 001.336 2.212l9 4.737c1.664.876 3.664-.331 3.664-2.213v-10.7z"
      ></path>
      <mask id="path-4-inside-1_616_708" fill="#fff">
        <rect
          width="18"
          height="5"
          x="25"
          y="38.263"
          rx="2"
          transform="rotate(-27.326 25 38.263)"
        ></rect>
      </mask>
      <rect
        width="18"
        height="5"
        x="25"
        y="38.263"
        fill="#fff"
        stroke="#fff"
        strokeWidth="5"
        mask="url(#path-4-inside-1_616_708)"
        rx="2"
        transform="rotate(-27.326 25 38.263)"
      ></rect>
      <mask id="path-5-inside-2_616_708" fill="#fff">
        <rect
          width="18"
          height="5"
          x="12"
          y="16.263"
          rx="2"
          transform="rotate(-27.326 12 16.263)"
        ></rect>
      </mask>
      <rect
        width="18"
        height="5"
        x="12"
        y="16.263"
        fill="#fff"
        stroke="#fff"
        strokeWidth="5"
        mask="url(#path-5-inside-2_616_708)"
        rx="2"
        transform="rotate(-27.326 12 16.263)"
      ></rect>
      <mask id="path-6-inside-3_616_708" fill="#fff">
        <rect
          width="18"
          height="5"
          rx="2"
          transform="scale(-1 1) rotate(-27.326 63.559 81.427)"
        ></rect>
      </mask>
      <rect
        width="18"
        height="5"
        fill="#fff"
        stroke="#fff"
        strokeWidth="5"
        mask="url(#path-6-inside-3_616_708)"
        rx="2"
        transform="scale(-1 1) rotate(-27.326 63.559 81.427)"
      ></rect>
      <mask id="path-7-inside-4_616_708" fill="#fff">
        <rect
          width="18"
          height="5"
          rx="2"
          transform="scale(-1 1) rotate(-27.326 11.807 97.167)"
        ></rect>
      </mask>
      <rect
        width="18"
        height="5"
        fill="#fff"
        stroke="#fff"
        strokeWidth="5"
        mask="url(#path-7-inside-4_616_708)"
        rx="2"
        transform="scale(-1 1) rotate(-27.326 11.807 97.167)"
      ></rect>
    </svg>
  )
}

export default NonArtsSpaceUsedForArtsIcon
