import React from 'react'

function TechnicalTheatreDesignShopIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        d="M24.5 35a1.5 1.5 0 01-1.5-1.5v-3a1.5 1.5 0 013 0v3a1.5 1.5 0 01-1.5 1.5zM29 33.5a1.5 1.5 0 103 0v-3a1.5 1.5 0 00-3 0v3z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M32.746 23.333h7.804c.8 0 1.45.622 1.45 1.39 0 .766-.65 1.388-1.45 1.388h-.261l-2.201 10.54C37.681 38.597 35.896 40 33.823 40H21.177c-2.073 0-3.858-1.402-4.265-3.35l-2.2-10.539h-.262c-.8 0-1.45-.622-1.45-1.389s.65-1.389 1.45-1.389h7.804l3.95-7.565c.245-.47.747-.768 1.296-.768.55 0 1.051.297 1.297.768l3.95 7.565zM17.67 26.111l2.087 9.995c.135.649.73 1.116 1.421 1.116h12.646c.69 0 1.286-.467 1.421-1.116l2.087-9.995H17.67zm11.835-2.778L27.5 19.495l-2.004 3.838h4.008z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default TechnicalTheatreDesignShopIcon
