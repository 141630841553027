import React from 'react'

function IndividualMusicPracticeRoomsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        d="M39.833 41.49V15.14h-6.62V11L16 15.442V44h17.213V17.65h3.972V44h2.648v-2.51z"
      ></path>
      <path
        fill="#003E7F"
        d="M30.521 32.181c-.154.847-.76 1.475-1.491 1.548a39.842 39.842 0 01-7.73 0c-.732-.073-1.337-.701-1.491-1.548-.094-.494-.141-1-.142-1.506v-.034c.008-1.015.672-1.855 1.531-1.936a39.884 39.884 0 017.936 0c.858.082 1.523.922 1.533 1.939v.033a8.269 8.269 0 01-.146 1.504zM28.252 23.417c0 1.932-1.321 3.5-2.951 3.5-1.63 0-2.951-1.568-2.951-3.5 0-1.933 1.321-3.5 2.95-3.5 1.63 0 2.952 1.567 2.952 3.5z"
      ></path>
    </svg>
  )
}

export default IndividualMusicPracticeRoomsIcon
