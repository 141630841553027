import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { SpaceTypeIcon } from 'components/icons'

const propTypes = {
  displayName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

function display_name(name, displayName) {
  if (displayName === null || displayName.trim() === '') {
    if (name !== null || name.trim() !== '') {
      return name
        .trim()
        .split('_')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
    }
  }

  return displayName
}

const defaultProps = {}

function SpaceTypesCard({ displayName, name }) {
  return (
    <div className={classnames('stat-card', 'card')}>
      <div className="stat-inner-with-image">
        <div className="image-block">
          <SpaceTypeIcon name={name} />
        </div>
        <p>{display_name(name, displayName)}</p>
        <p></p>
      </div>
    </div>
  )
}

SpaceTypesCard.propTypes = propTypes
SpaceTypesCard.defaultProps = defaultProps

export default SpaceTypesCard
