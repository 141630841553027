import React from 'react'

function CeramicsKilnRoomIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        d="M16.795 30.182l.983.703a1.439 1.439 0 001.627 0 3.543 3.543 0 014.001 0 1.439 1.439 0 001.628 0 3.543 3.543 0 014 0 1.439 1.439 0 001.628 0 3.543 3.543 0 014 0 1.439 1.439 0 001.628 0l.983-.703c-1.322-3.667-3.865-7.09-4.475-9.259-.373-1.345-.305-2.72 0-3.973H21.27c.271 1.253.373 2.598 0 3.973-.61 2.17-3.153 5.592-4.475 9.26zM37.477 33.23a3.543 3.543 0 01-4.001 0 1.439 1.439 0 00-1.628 0 3.543 3.543 0 01-4 0 1.439 1.439 0 00-1.628 0 3.555 3.555 0 01-2 .61 3.555 3.555 0 01-2-.61 1.439 1.439 0 00-1.628 0 3.543 3.543 0 01-4 0l-.34-.245c-.61 2.781-.237 5.593 2.51 8.007.745.672 1.796 1.008 2.847 1.008h10.782c1.05 0 2.102-.367 2.848-1.008 2.746-2.414 3.12-5.226 2.509-8.007l-.271.245zM20.728 15.209H33.34c.237-.642.543-1.223.814-1.681.407-.703-.17-1.528-1.051-1.528H20.999c-.882 0-1.458.825-1.051 1.528.237.489.542 1.07.78 1.68z"
      ></path>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="3"
        d="M22.142 15h9.782l-1.166 3.53-.076.229v3.923H23.5V18.736l-.09-.249L22.142 15z"
      ></path>
    </svg>
  )
}

export default CeramicsKilnRoomIcon
