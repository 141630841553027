import React from 'react'

function ArtOnACartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <mask id="path-2-inside-1_871_14" fill="#fff">
        <rect width="26" height="7" x="15" y="19" rx="2"></rect>
      </mask>
      <rect
        width="26"
        height="7"
        x="15"
        y="19"
        fill="#fff"
        stroke="#fff"
        strokeWidth="6"
        mask="url(#path-2-inside-1_871_14)"
        rx="2"
      ></rect>
      <mask id="path-3-inside-2_871_14" fill="#fff">
        <rect width="26" height="7" x="15" y="33" rx="2"></rect>
      </mask>
      <rect
        width="26"
        height="7"
        x="15"
        y="33"
        fill="#fff"
        stroke="#fff"
        strokeWidth="6"
        mask="url(#path-3-inside-2_871_14)"
        rx="2"
      ></rect>
      <rect
        width="24.5"
        height="1.5"
        x="15.75"
        y="38.25"
        fill="#fff"
        stroke="#fff"
        strokeWidth="1.5"
        rx="0.75"
        transform="rotate(-90 15.75 38.25)"
      ></rect>
      <rect
        width="16.5"
        height="1.5"
        x="38.75"
        y="38.25"
        fill="#fff"
        stroke="#fff"
        strokeWidth="1.5"
        rx="0.75"
        transform="rotate(-90 38.75 38.25)"
      ></rect>
      <circle
        cx="20.5"
        cy="40.5"
        r="3.5"
        fill="#fff"
        stroke="#003E7F"
        strokeWidth="2"
      ></circle>
      <circle
        cx="35.5"
        cy="40.5"
        r="3.5"
        fill="#fff"
        stroke="#003E7F"
        strokeWidth="2"
      ></circle>
    </svg>
  )
}

export default ArtOnACartIcon
