import React from 'react'

function SpaceTypeAllocationsIcon() {
  return (
    <svg
      width="83px"
      height="82px"
      viewBox="0 0 83 82"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        fill="#003E7F"
        d="M35 70c19.33 0 35-15.67 35-35S54.33 0 35 0 0 15.67 0 35s15.67 35 35 35z"
      ></path>
      <rect
        width="29"
        height="29"
        x="20.5"
        y="20.5"
        stroke="#fff"
        strokeWidth="5"
        rx="2.5"
      ></rect>
      <rect
        width="15"
        height="15"
        x="19.5"
        y="35.5"
        fill="#fff"
        stroke="#fff"
        strokeWidth="3"
        rx="3.5"
      ></rect>
    </svg>
  )
}

export default SpaceTypeAllocationsIcon
