import React from 'react'

function RecordingStudioIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        d="M34.668 20.203h-1.875V18.81C32.793 15.61 30.2 13 27 13s-5.793 2.61-5.793 5.81v1.393h-1.875A2.342 2.342 0 0017 22.543v1.86c0 2.77 1.157 5.436 3.172 7.325a9.852 9.852 0 005.429 2.6v4.865h-2.136c-.775 0-1.4.627-1.4 1.404a1.4 1.4 0 001.4 1.403h7.07a1.4 1.4 0 001.4-1.403c0-.777-.625-1.404-1.4-1.404H28.4V34.33C33.278 33.637 37 29.277 37 24.104v-1.562a2.342 2.342 0 00-2.332-2.339zm-.467 3.901c0 3.957-2.938 7.26-6.707 7.512a7.143 7.143 0 01-5.41-1.936 7.165 7.165 0 01-2.285-5.277V23.01h1.408v1.393c0 3.21 2.593 5.819 5.793 5.819s5.793-2.61 5.793-5.819V23.01H34.2v1.094z"
      ></path>
    </svg>
  )
}

export default RecordingStudioIcon
