import React from 'react'

function NoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M14 19.63c0-3.096 2.534-5.63 5.63-5.63h15.747C38.474 14 41 16.534 41 19.63v15.747A5.633 5.633 0 0135.377 41H19.631C16.534 41 14 38.474 14 35.377V19.631zm20.794-2.546h-14.58c-1.75 0-3.13 1.38-3.13 3.13v14.58c0 1.75 1.38 3.122 3.13 3.122h14.58a3.09 3.09 0 003.122-3.122v-14.58c0-1.75-1.373-3.13-3.122-3.13z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M32.379 21a.875.875 0 00-.62.252L21.254 31.756a.875.875 0 001.24 1.234l10.498-10.498A.875.875 0 0032.38 21z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M31.759 21.252a.875.875 0 01.62-.252.875.875 0 01.804.536.875.875 0 01-.19.956L22.494 32.99a.875.875 0 01-1.24-1.234l10.504-10.504zM22.92 33.413a1.476 1.476 0 01-2.09 0h-.001a1.475 1.475 0 010-2.08l.001-.001 10.504-10.504.003-.003a1.475 1.475 0 011.046-.425h-.004m1.037 2.517a1.477 1.477 0 000-2.09l-.002-.002a1.475 1.475 0 00-1.031-.425m1.033 2.517L22.92 33.413"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default NoneIcon
