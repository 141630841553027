import React from 'react'

function BlackBoxTheatreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        d="M29.375 13h-17.29s-1.544 19.333 8.557 19.333c10.1 0 8.733-19.333 8.733-19.333zm-14.284 6.51s.796-1.667 2.15-1.667c1.353 0 2.122 1.706 2.122 1.706l-4.272-.04zm5.605 9.113c-2.476 0-3.87-2.916-3.87-2.916a14.66 14.66 0 003.91.716 13.849 13.849 0 003.83-.71s-1.36 2.897-3.836 2.897l-.034.013zm1.21-9.114s.803-1.666 2.157-1.666 2.116 1.706 2.116 1.706l-4.272-.04zM42.939 22.667H30.778a25.488 25.488 0 01-1.33 5.159h3.675s-.71 1.706-2.04 1.706a2.375 2.375 0 01-1.071-.38 2.315 2.315 0 01-.763-.831 11.92 11.92 0 01-2.266 3.634 6.92 6.92 0 01-.452.43C27.395 37.193 29.488 42 34.406 42c9.875 0 8.533-19.333 8.533-19.333zm-8.533 13.965a13.242 13.242 0 00-3.741.71s1.362-2.918 3.78-2.918c2.42 0 3.782 2.919 3.782 2.919a14.016 14.016 0 00-3.821-.71zm3.323-7.1c-1.33 0-2.074-1.706-2.074-1.706h4.14s-.737 1.706-2.066 1.706z"
      ></path>
    </svg>
  )
}

export default BlackBoxTheatreIcon
