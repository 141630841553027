import React from 'react'

function MusicRoomIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        d="M41 41.262V12.517h-7.222V8L15 12.845V44h18.778V15.255h4.333V44H41v-2.738z"
      ></path>
      <path
        fill="#003E7F"
        d="M28.543 20.318c.32.031.567.359.568.753v7.564c0 1.662-1.112 3.025-2.469 3.025-1.357 0-2.469-1.363-2.469-3.025 0-1.663 1.113-3.026 2.47-3.026.448 0 .87.15 1.234.41v-3.931l-4.939 1.815v6.245c0 1.662-1.112 3.025-2.469 3.025-1.357 0-2.469-1.363-2.469-3.025s1.112-3.025 2.469-3.025c.45 0 .87.149 1.235.41l-.001-4.193c0-.333.18-.628.44-.723l6.173-2.27a.517.517 0 01.227-.029z"
      ></path>
    </svg>
  )
}

export default MusicRoomIcon
