import React from 'react'

function KeyboardLabIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        d="M28.876 14H40.38c.719 0 1.374.324 1.85.848s.77 1.245.77 2.037v22.232c0 .756-.269 1.448-.706 1.963l-.061.075c-.475.522-1.131.845-1.852.845h-4.234c-.62 0-1.19-.238-1.639-.635a2.454 2.454 0 01-1.636.635h-4.235c-.619 0-1.188-.238-1.637-.634a2.463 2.463 0 01-1.637.634h-4.235c-.62 0-1.19-.238-1.639-.635a2.454 2.454 0 01-1.636.635H13.62c-.723 0-1.378-.324-1.851-.845A3.027 3.027 0 0111 39.117V16.885c0-.795.294-1.517.768-2.038l.067-.068c.469-.482 1.097-.777 1.784-.777h15.259L28.876 14zm-12.247 2.164h-3.013a.626.626 0 00-.432.177l-.03.035a.757.757 0 00-.194.509v22.232c0 .197.074.379.193.508a.625.625 0 00.462.213h4.234c.18 0 .345-.082.462-.213a.75.75 0 00.194-.508v-8.859a2.874 2.874 0 01-1.037-.732 3.304 3.304 0 01-.84-2.223V16.165l.001-.001zm3.841 14.093v8.858c0 .197.075.38.194.509a.625.625 0 00.461.212h4.235c.179 0 .344-.081.462-.212l.032-.034a.774.774 0 00.16-.472v-8.863a2.875 2.875 0 01-1.035-.732 3.305 3.305 0 01-.84-2.223V16.162h-1.79V27.3a3.31 3.31 0 01-.84 2.223 2.874 2.874 0 01-1.037.732l-.002.002zm7.51 0v8.858c0 .197.074.38.193.509a.625.625 0 00.462.212h4.234c.18 0 .345-.081.462-.212a.749.749 0 00.193-.509v-8.858a2.874 2.874 0 01-1.036-.733 3.305 3.305 0 01-.84-2.223V16.164h-1.79V27.3a3.31 3.31 0 01-.84 2.223 2.874 2.874 0 01-1.037.733h-.001zm9.386-14.093V27.3a3.31 3.31 0 01-.84 2.223 2.872 2.872 0 01-1.037.733v8.858c0 .197.074.38.193.509a.625.625 0 00.462.212h4.235c.179 0 .344-.081.461-.212l.032-.034a.768.768 0 00.162-.476V16.882c0-.2-.073-.38-.191-.511a.629.629 0 00-.464-.21h-3.013v.003z"
      ></path>
    </svg>
  )
}

export default KeyboardLabIcon
